import { render, staticRenderFns } from "./machineryMarket.vue?vue&type=template&id=38fb2eb7&scoped=true&"
import script from "./machineryMarket.vue?vue&type=script&lang=js&"
export * from "./machineryMarket.vue?vue&type=script&lang=js&"
import style0 from "./machineryMarket.vue?vue&type=style&index=0&id=38fb2eb7&lang=scss&scoped=true&"
import style1 from "./machineryMarket.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fb2eb7",
  null
  
)

export default component.exports